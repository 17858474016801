import React, { useEffect, useState } from "react";
// import Home from "./Home";
import { MdImageAspectRatio, MdOutlineShoppingCart } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaShoppingCart, FaUserCog } from "react-icons/fa";
import { useCart } from "react-use-cart";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import firebase, { db, storage } from "../firebaseconfig";
import { BiCheck, BiPhone, BiPhoneCall, BiShoppingBag } from "react-icons/bi";
// import Auth from "./Auth";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Nav from "../component/Nav";
import MobileNav from "./MobileNav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import S6 from "../Assets/p6.mp4";
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import PaystackForm from "./PaystackForm";
import axios from "axios";



// import { MenuItem, Select } from "@mui/material";



// const firebaseConfig = {
//   // Your Firebase config
//   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
//   authDomain: "nextberries-cb5b9.firebaseapp.com",
//   projectId: "nextberries-cb5b9",
//   storageBucket: "nextberries-cb5b9.appspot.com",
//   messagingSenderId: "44749615279",
//   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
//   measurementId: "G-9QZ27VRVHL",
// };

// firebase.initializeApp(firebaseConfig);

// const firestore = firebase.firestore();


function FProfile() {
    const { uid } = useParams();
    const {
      isEmpty,
      items,
      cartTotal,
      updateItemQuantity,
      removeItem,
      emptyCart,
    } = useCart();

    const nav = useNavigate();


    const currentUser = firebase.auth().currentUser?.uid

    // async function MUser() {
    //   try {
    //     const querysnapshot = await firebase.firestore()
    // .collection ("Messages")
    // .where('participantsArray', "array-contains", currentUser.uid)
    // .get();
    
    // let chatRoomId = null;
    
    // querysnapshot.docs.forEach((doc) => {
    //   const data = doc.data();
    //   if(data.participantsArray.includes(uid)) {
    //     chatRoomId = doc.id;
    //   }
    // });
    
    // if (!chatRoomId) {
    //   const newRoom = await firebase.firestore()
    //   .collection('Messages')
    //   .add({
    //             createdAt: new Date(),
    //             lastMessage: '',
    //             participantsArray: [currentUser.uid, uid],
    //             participants: [ {uid: currentUser.uid, displayName: CSuser?.name, photoURL: CSuser.displayPhoto,}, {uid: uid, displayName: UserData?.name, photoURL: UserData?.displayPhoto, }]
            
    //   })
    
    //   chatRoomId = newRoom.id
    // }
    // nav.navigate('Chat', {id: chatRoomId, uid: uid});
    
    // // dispatch(setUid(userUid))
    // // dispatch(setUid(userData.uid))
    
    //   }catch (error) {
    //     console.log('Error', error);
    //   }
    // }

  const [user, setUser] = useState(null);

  const [UserData, setUserData] = useState();
  // fetch the user data from firebase
  useEffect(() => {
    const subscriber = firebase.firestore()
        .collection('Users')
        .doc(uid)
        .onSnapshot(documentSnapshot => {
            setUserData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
    }, []);

  const [CSuser, setCSUser] = useState();
  // fetch the user data from firebase
  useEffect(() => {
    const subscriber = firebase.firestore()
        .collection('Users')
        .doc(firebase.auth()?.currentUser?.uid)
        .onSnapshot(documentSnapshot => {
            setCSUser(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
    }, []);


  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });

  //   return () => unsubscribe(); // Cleanup the event listener on unmount
  // }, []);



  //   const handleSubmit = async (e) => {



  //     if (file) {
  //       alert("You are about to update this priest data with a new passport!")
  //       const storageRef = ref(storage, `/passport/${file.name}`)
  
  //       const uploadTask = uploadBytesResumable(storageRef, file)
  
  //       uploadTask.on("state_changed", (snapshot) => {
  
  //         const prog = Math.round(
  
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         )
  //         setProgress(prog)
  
  //       }, (err) => console.log(err), () => {
  
  //         getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
  
  //           const passportURL = url
  //           // const DateofPosting = new Date(dop)
  
  
  //           await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {
            

  //           }).then(()=>{
  
  //             toast.success("Priest Updated successfully!")
  //             // window.location.reload()
  
  //           }).catch((err)=>{
  //             toast.error("An Error occured")
  //             // window.location.reload()
  //           })
  
  //         })
  
  //       })
  
  //     } else if(fileVideo) {
  //       alert("You are about to update this priest data with a new passport!")
  //       const storageRef = ref(storage, `/passport/${file.name}`)
  
  //       const uploadTask = uploadBytesResumable(storageRef, file)
  
  //       uploadTask.on("state_changed", (snapshot) => {
  
  //         const prog = Math.round(
  
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         )
  //         setProgress(prog)
  
  //       }, (err) => console.log(err), () => {
  
  //         getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
  
  //           const passportURL = url
  //           // const DateofPosting = new Date(dop)
  
  
  //           await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {
            

  //           }).then(()=>{
  
  //             toast.success("Updated successfully!")
  //             // window.location.reload()
  
  //           }).catch((err)=>{
  //             toast.error("An Error occured")
  //             // window.location.reload()
  //           })
  
  //         })
  
  //       })
  
  //     } else if (file, fileVideo === null){
  
  // const Run=async()=>{
  //   alert("You are about to update this priest data!")
  //   // const DateofPosting = new Date(dop)
  //   await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {
      
  
  //   }).then(()=>{
  
  //     toast.success("Priest Updated successfully!")
  //     // window.location.reload()
  
  //   }).catch((err)=>{
  //     toast.error("An Error occured")
  //     // window.location.reload()
  //   })
  
  // }
  // Run()
  // }
  
  
  //   };





  const [file, setFile] = useState(null);
  const [fileVideo, setFileVideo] = useState(null);
  const [progress, setProgress] = useState(0)


  
  const handlePaymentSuccess = (response, userData, Hours, Amount) => {
    console.log("Payment successful:", response);

    const payeeName = CSuser?.name;
    const payeeLocation = CSuser?.location;
    const payeeEmail = CSuser?.email;
    // const payeePostalCode = userData.postalCode;
    const FuckHours = Hours;
    const Note = userData.note;
    const payeePhoneNumber = userData.phoneNumber || CSuser?.phoneNumber;

    // const productsInCart = items.map((item) => ({
    //   id: item.id,
    //   name: item.title,
    //   quantity: productQuantities[item.id] || item.quantity,
    // }));

    const data = {
      amount: Amount,
      createdAt: new Date(),
      productPaidFor: UserData?.name,
      payeeImage: CSuser?.displayPhoto,
      product_Video: UserData?.displayVideo,
      // quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      CSuserID: CSuser?.uid,
      UserDataID: UserData?.uid,
      FuckHours,
      Note,
      payeeLocation,
      payeeEmail,
      // payeePostalCode,
      payeePhoneNumber,
      UserPhoneNumber: UserData?.phoneNumber,
      // productsInCart,
      status: 'Successful'

    };


    firebase.firestore()
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

    firebase.firestore()
      .collection("Users")
      .doc(uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      firebase.firestore()
      .collection("Users")
      .doc(uid)
      .update({balance: UserData?.balance + Amount})
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      // navigate()

      
    };
    
    // const navigate = <Link to={'/order'} />

  const handlePaymentClose = () => {
    console.log("Payment cancelled");
  };


  const handleOrderAttempts = (response, userData, Hours, Amount) => {
    
    console.log("Payment Attempt:", response);

    const payeeName = CSuser?.name;
    const payeeLocation = CSuser?.location;
    const payeeEmail = CSuser?.email;
    const Note = userData.postalCode;
    const FuckHours = Hours;
    const payeePhoneNumber = userData.phoneNumber || CSuser?.phoneNumber;

    // const productsInCart = items.map((item) => ({
    //   id: item.id,
    //   name: item.title,
    //   quantity: productQuantities[item.id] || item.quantity,
    // }));

    const data = {
      amount: Amount,
      createdAt: new Date(),
      productPaidFor: UserData?.name,
      payeeImage: CSuser?.displayPhoto,
      product_Video: UserData?.displayVideo,
      // quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      CSuserID: CSuser?.uid,
      UserDataID: UserData?.uid,
      payeeLocation,
      payeeEmail,
      Note,
      FuckHours,
      payeePhoneNumber,
      UserPhoneNumber: UserData?.phoneNumber,
      // productsInCart,
      status: 'Attempt'

    };

    firebase.firestore()
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Attempts")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

    firebase.firestore()
      .collection("Users")
      .doc(uid)
      .collection("Attempts")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

   };


   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const [subscribed, setSubscribed] = useState(false);
   const [response, setResponse] = useState(null);
 
   const handleSubscribe = async () => {
    console.log(process.env.REACT_APP_PAYSTACK_SECRET_KEY);
     setLoading(true);
     setError(null);
 
     try {
       // Make request to Paystack subscription endpoint
       const response = await axios.post('https://api.paystack.co/subscription', {
         // Subscription parameters
       }, {
         headers: {
          //  Authorization: `Bearer ${"sk_live_8b91aa3a30abfb4c875290ba10580bde1c6ca16f"}`,
           Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
           'Content-Type': 'application/json',
         },
       });
 
       // Handle successful subscription
       console.log(response.data);
       setSubscribed(true);
     } catch (error) {
       // Handle subscription error
       console.error('Subscription error:', error.response.data);
       setError('Failed to subscribe. Please try again later.');
     }
 
     setLoading(false);
   };

   const handleCreatePlan = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://api.paystack.co/plan',
        {
          name: 'Queen Monthly',
          interval: 'monthly',
          amount: 500000,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setResponse(response.data);
    } catch (error) {
      console.error('Error creating plan:', error);
      setError('Failed to create plan. Please try again later.');
    }

    setLoading(false);
  };

  const handleInitializeTransaction = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://api.paystack.co/transaction/initialize',
        // 'https://api.paystack.co/transaction/initialize',
        {
          email: 'customer@email.com',
          amount: '500000',
          plan: 'PLN_q9n2phn4jp3p3a0', // Replace with your actual plan ID
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setResponse(response.data);
       // Redirect user to Paystack checkout page
       window.location.href = response.data.data.authorization_url;
    } catch (error) {
      console.error('Error initializing transaction:', error);
      setError('Failed to initialize transaction. Please try again later.');
    }

    setLoading(false);
  };
 



  return (
    <div 
    className="bg-[#656564] h-[100%] w-screen  lg:p-32 p-10 lg:pt-28 pt-24 text-center items-center justify-center"
    // className="fixed z-50 md:top-20 top-10 left-[5%] md:left-[95%]"
    >

      <h1 className="text-white text-5xl text-center mb-5">
       Get {UserData?.name}
      </h1>

      {/* <input className="bg-[#fff] text-[#000] text-center text-sm ml-2 mb-0 rounded-lg p-4"
      placeholder="Change DisplayName..."
/> */}
<br />
{loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {response && (
        <div>
          <p>Transaction initialized successfully:</p>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
<Link onClick={handleInitializeTransaction} className="bg-[#00aff0] text-[#fff] flex justify-center items-center text-center text-sm rounded-2xl mb-10 p-5">
                 Subscribe to see {UserData?.name}'s VIP content
                </Link>


{/* {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {response && (
        <div>
          <p>Plan created successfully:</p>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
<Link onClick={handleCreatePlan} className="bg-[#333] text-[#fff] flex justify-center items-center text-center text-sm rounded-lg mb-10 p-5">
                 Subscribe to see {UserData?.name}'s VIP content
                </Link> */}
{/* {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {subscribed ? (
        <p>Successfully subscribed!</p>
      ) : (
<Link onClick={handleCreatePlan} className="bg-[#333] text-[#fff] flex justify-center items-center text-center text-sm rounded-lg mb-10 p-5">
                 Subscribe to see {UserData?.name}'s VIP content
                </Link>
      )} */}


<div  className="lg:flex grid text-center items-center justify-center">

{/* <Link onClick={MUser} className="bg-[#333] text-[#fff] text-center text-sm rounded-lg p-5">
                 Message
                </Link> */}
                
{/* <Link className="bg-[#333] text-[#fff] text-center text-sm rounded-lg lg:ml-5 lg:mt-0 mt-5 p-5">
               Pay To Fuck {UserData?.name} 
                </Link> */}

<PaystackForm
                    uid={uid}
                    // amount={UserData?.offlineFee} // Use the cart total as the payment amount
                    email={firebase?.auth()?.currentUser?.email} // Replace with the customer's email
                    onSuccess={handlePaymentSuccess}
                    onClose={handlePaymentClose}
                    handleOrderAttempts={handleOrderAttempts}
                    />

<br />


<p className="bg-[#fff] text-[#000] text-center text-sm rounded-lg lg:ml-5 lg:mt-0 mt-5 p-5">
                  {UserData?.name}'s Location:  { UserData?.location}
                </p>
<Link onClick={() => alert(`Pay ${UserData?.name} You'll See The Number In Your Hook Up Screen`)} className="bg-[#333] text-[#fff] flex justify-center items-center text-center text-sm rounded-lg lg:ml-5 lg:mt-0 mt-5 p-5">
                 Call  {UserData?.name}   < BiPhone />
                </Link>

</div>

       <div className="lg:flex justify-center mb-20 mt-10">

          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : UserData?.displayPhoto
              }
              alt=""
              className="h-60 w-36 ml-2 mb-5 rounded-lg"
            />
              {/* <div className="">
                <label htmlFor="file" className="bg-[#fff] cursor-pointer text-[#000] text-sm ml-2 rounded-lg p-3">
                 Change Display Video
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                >

                </input>
              </div> */}
          </div >

          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <video
              src={
                fileVideo
                  ? URL.createObjectURL(fileVideo)
                  : UserData?.displayVideo
              }
              autoPlay
              loop
              muted
              alt=""
              className="h-60 w-36 ml-2 mb-5 rounded-lg"
            />
              {/* <div className="">
                <label htmlFor="file" className="bg-[#fff] cursor-pointer text-[#000] text-sm ml-2 rounded-lg p-3">
                 Change Display Video
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                >

                </input>
              </div> */}
          </div >
          {/* <div className="border-2 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className="h-76 w-36  p-3 mb-5 bg-[#ccc] text-[#000] rounded-lg"
            > 
            <p>
              $20
            </p>
             </div>

             <p className="bg-[#ccc] text-[#000] text-center text-sm rounded-lg p-4">
                 Subscribtion
                </p>
        
          </div > */}
          
          {/* <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className=" p-3 mb-2 bg-[#fff] text-[#000] rounded-lg"
            > 
            <p>
              ${UserData?.onlineFee}
            </p>
             </div>
             
<div
              alt=""
              className=" text-sm p-3 mb-5 bg-[#ccc] text-[#000] rounded-lg"
            > 
            <p>
            Online Hookup Price...           </p>
             </div>
<br />
<p>
             {UserData?.onlineRules}
            </p>
<br />
                <Link className="bg-[#fff] text-[#000] text-center text-sm rounded-lg p-4">
                 Pay
                </Link>
          </div > */}



          
          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className=" p-3 mb-2 bg-[#fff] text-[#000] rounded-lg"
            > 
            <p>
              ${UserData?.offlineFee}
            </p>
             </div>
             

<div
              alt=""
              className="text-sm p-3 mb-0 bg-[#ccc] text-[#000] rounded-lg"
            > 
            <p>
            Offline Hookup Price...            </p>
             </div>
<br />
<p>
Meet And Fuck For 1hr
             <br />
             Venue: Hotel
            </p>

                      <br />
                {/* <Link className="bg-[#fff] text-[#000] text-center text-sm rounded-lg p-4">
                 Pay
                </Link> */}
                  <PaystackForm
                    uid={uid}
                    // amount={UserData?.offlineFee} // Use the cart total as the payment amount
                    email={firebase?.auth()?.currentUser?.email} // Replace with the customer's email
                    onSuccess={handlePaymentSuccess}
                    onClose={handlePaymentClose}
                    handleOrderAttempts={handleOrderAttempts}
                    />
                                          <br />

          </div >
     

        </div>

         

      <Nav />
      <MobileNav />
      <ToastContainer />


  </div>
    )
}

export default FProfile