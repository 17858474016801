import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import Homes from "./Homes";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import Nav from "../component/Nav";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import MobileNav from "./MobileNav";
import Video2 from "../Assets/v90.mp4";


const firebaseConfig = {
  apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
  authDomain: "agency-b5b65.firebaseapp.com",
  projectId: "agency-b5b65",
  storageBucket: "agency-b5b65.appspot.com",
  messagingSenderId: "30425467879",
  appId: "1:30425467879:web:aef3dadb4967b61c258b42",
  measurementId: "G-1QKVN1SZDF"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Shop = () => {
  const trandingSliderRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSliderData = async () => {
      const db = firebase.firestore();
      const collection = db.collection("Products");

      try {
        const snapshot = await collection.get();
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSliderData(data);

        const trandingSlider = new Swiper(trandingSliderRef.current, {
          // Initialize Swiper instance
        //   direction: "horizontal",
        // effect: "contain",
        // grabCursor: true,
        // centeredSlides: true,
        // loop: true,
        // slidesPerView: "auto",
        // coverflowEffect: {
        //   rotate: 0,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 2.5,
        // },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // autoplay: {
        //   delay: 500,
        //   disableOnInteraction: false,
        // },
          // ...
        });

        const timer = setInterval(() => {
          if (trandingSlider && trandingSliderRef.current) {
            trandingSlider.slideNext();
          }
        }, 2000); // Adjust the interval value (in milliseconds) for auto sliding

        return () => {
          clearInterval(timer);
          if (trandingSlider) {
            trandingSlider.destroy();
          }
        };
      } catch (error) {
        console.log("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  return (
    <div className=" bg-[#656564] items-center justify-center h-screen w-screen">

<div
  // style={{width: '100%'}}
  className="hidden lg:flex w-[100%] h-[100%] justify-center items-center object-cover top-[0px] absolute"
  // className="hidden lg:flex w-screen h-[100%] justify-center items-center object-cover top-[180px] absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    // position: absolute;
    width: 100%;
    top: 20px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video2} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/> 
   
<div
  // style={{width: '100%'}}
  className="lg:hidden w-[100%]  h-[100%] flex justify-center items-center object-cover top-[0px] absolute"
  // className="lg:hidden w-screen  h-[100%] flex justify-center items-center object-cover top-[240px] absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    // position: absolute;
    width: 100%;
    // top: -20px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video2} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>  

    {/* <div className="w-[100%] h-screen bg-[#656564]"> */}
      <div className="">
        {/* <img src={bg} alt="" className="w-[100%]" />
        <div className="swiper md:h-[1000px] h-screen tranding-slider g-gradient-to-r from-slate-00 via-slate-300 to-slate-50 bg-[url('https://firebasestorage.googleapis.com/v0/b/nextberries-9957f.appspot.com/o/special%2FWEBSITE%20PROTOTYPE.png?alt=media&token=045aa610-8b03-468d-9166-5054bbb018f9')] bg-no-repeat md:bg-cover bg-center bg-cover">
          <div
            ref={trandingSliderRef}
            className=" md:ml-[47%] ml-[0%] text-center md:w-[17.9%]"
          >
            <div
              className="swiper-wrapper  md:mt-36 z-20"
              ref={trandingSliderRef}
            >
              {sliderData.map((slide, index) => (
                <div
                  className="swiper-slide tranding-slide border- md:w-[40%] border-red-50"
                  key={index}
                >
                  <Link to={`/product-details/${slide.id}`}>
                    <div className="tranding-slide-img md:-mt-[20%] relative text-center md:w-[40%]">
                      <p className="text-white font-bankGothic md:-top-10 md:-left-6 left-28  -top-5 absolute">
                        {slide.name}
                      </p>
                      <img
                        src={slide.product_image}
                        alt={`Slide ${index + 1}`}
                        className=" md:ml-0 ml-[32%] mt-8 h-80 md:mt-20"
                      />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="bg-[#656564] h-[100%]">
        {/* <div className="bg-[#656564]"> */}
          <Homes />
        </div>
      </div>

      <Nav />
      <MobileNav />
    </div>
  );
};

export default Shop;
