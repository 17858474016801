import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineShoppingCart } from "react-icons/md";
import PaystackForm from "./PaystackForm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Nav from "../component/Nav";
import Homes from "./Homes";
import MobileNav from "./MobileNav";
import { BiCheck } from "react-icons/bi";

const firebaseConfig = {
  apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
  authDomain: "agency-b5b65.firebaseapp.com",
  projectId: "agency-b5b65",
  storageBucket: "agency-b5b65.appspot.com",
  messagingSenderId: "30425467879",
  appId: "1:30425467879:web:aef3dadb4967b61c258b42",
  measurementId: "G-1QKVN1SZDF"
};
  
  firebase.initializeApp(firebaseConfig);
  
  const firestore = firebase.firestore();

export default function Hook() {

    const {
        isEmpty,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
      } = useCart();

      const [productData, setProductData] = useState([]);


      useEffect(() => {
        async function getResponse() {
    
          const db = firebase.firestore();
          const collection = db
          .collection("Users")
          .doc(firebase.auth().currentUser.uid)
          .collection("Payments")
          .orderBy("createdAt", "desc")
          const snapshot = await collection.get();
          const Products = [];
    
          snapshot.forEach((doc) => {
            Products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
    
          setProductData(Products);
        }
    
        getResponse();
      }, []);

      
  const [CSuser, setCSUser] = useState();
  // fetch the user data from firebase
  useEffect(() => {
    const subscriber = firebase.firestore()
        .collection('Users')
        .doc(firebase.auth()?.currentUser?.uid)
        .onSnapshot(documentSnapshot => {
            setCSUser(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
    }, []);

  return (
    <>
    <div className=" p-10 pt-20 bg-[#656564]">
      <h1 className="text-white text-6xl text-center m-5">
        Hook Up
      </h1>
      {/* <h1 className="text-light">{isEmpty ? "Your Cart is Empty" : ""}</h1> */}
      <div className="justify-content-center">
        <div
          responsive="sm"
          striped
          bordered
          hover
          className="mb-5 border-2 items-center justify-center lg:flex grid border-white rounded-md p-2"
        >
          <tbody>
            {productData?.map((item, index) => {
              return (
                <>
                {
                  item.payeeName === CSuser?.name ?
                <tr
                  key={index}
                  className="md:inline justify-center lg:border-0 border-2 rounded-lg m-2 p-5 text-sm text-center grid grid-rows-1"
                >
                  <td>
                    <div
                      className="rounded-lg m-2  md:flex flex-col text-center justify-center drop-shadow-2xl shadow-2xl"
                      style={{
                        background: "white",
                        height: "8rem",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="p-2 text-center rounded-md">
                        <video
                          src={item.product_Video}
                          autoPlay
                          loop
                          muted
                          className="w-32 p-10 ml-3 md:ml-0 text-center shadow-2xl drop-shadow-2xl hover:scale-110 duration-500"
                          alt={item.payeeName}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="md:pl-10  text-[#fff]">
                  You've Payed For {item?.productPaidFor} 
                    </h6>
                  </td>
                  <td className="text-green-500 lg:p-10">$ {item.amount}</td>

                  <td className="">Fuck Hours {item.FuckHours}hr</td>
                  <td>
              
                  <button
                  
                  className=" m-3 p-3  rounded-full drop-shadow-2xl shadow-2xl bg-green-600 text-white duration-500"
                >
                  <BiCheck />
                    </button>{" "}
                    <br className="md:hidden" />
                    <button
                      variant="danger"
                      className=" text-green-600  m-2 font-bold bg-white rounded-lg duration-500 p-3"
                    >
                      Successful
                    </button>
                    <br className="md:hidden" />
                    <button
                    onClick={() => window.open(`tel:${item?.UserPhoneNumber}`)}
                      variant="danger"
                      className=" text-green-600 m-2 font-bold bg-white rounded-lg duration-500 p-3"
                    >
                    Call: { item?.UserPhoneNumber}
                    </button>
                  </td>

                  <td>
                    <h6 className="md:pl-10">
                  Your Note To {item.productPaidFor} :
                  <br />
                  {item.Note}
                    </h6>
                  </td>
                </tr>

                  :

                  <tr
                  key={index}
                  className="md:inline justify-center lg:border-0 border-2 rounded-lg m-2 p-5 text-sm text-center grid grid-rows-1"
                >
                  <td>
                    <div
                      className="rounded-lg  md:flex flex-col text-center justify-center m-2 drop-shadow-2xl shadow-2xl"
                      style={{
                        background: "white",
                        height: "8rem",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="p-2 text-center rounded-md">
                        <img
                          src={item.payeeImage}
                          // autoPlay
                          // loop
                          // muted
                          className="w-32 p-10 ml-3 md:ml-0 text-center shadow-2xl drop-shadow-2xl hover:scale-110 duration-500"
                          alt={item.payeeName}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="md:pl-10  text-[#fff]">
                   {item?.payeeName} Paid For You 
                    </h6>
                  </td>
                  <td className="text-green-500 lg:p-10">$ {item.amount}</td>

                  <td className="">Fuck Hours {item.FuckHours}hr</td>
                  <td>
              
                    <button
                  
                      className=" m-3 p-3  rounded-full drop-shadow-2xl shadow-2xl bg-green-600 text-white duration-500"
                    >
                      <BiCheck />
                    </button>
                    <br className="md:hidden" />
                    <button
                      variant="danger"
                      className=" text-green-600 m-3 font-bold bg-white rounded-lg duration-500 p-3"
                    >
                      Successful
                    </button>
                    <br className="md:hidden" />
                    <button
                    onClick={() => window.open(`tel:${item?.payeePhoneNumber}`)}
                      variant="danger"
                      className=" text-green-600 m-3 font-bold bg-white rounded-lg duration-500 p-3"
                    >
                  Call: { item?.payeePhoneNumber}
                    </button>
                  </td>

                  <td>
                    <h6 className="md:pl-10">
                  {item?.payeeName}'s Note To You :
                  <br />
                  {item.Note}
                    </h6>
                  </td>
                </tr>

                }
                </>
              );
            })}
          </tbody>
        </div>
      </div>
         <div className="bg-[#656564]">
          <Homes />
        </div>
      <Nav />
      <MobileNav />
    </div>
    </>
  )
}
