import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Google from "../Components/Google";
import { Link } from "react-router-dom";
import firebase from "../firebaseconfig";
import V2 from "../Assets/l1.PNG";


const Nav = ({ }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isShopAllOpen, setIsShopAllOpen] = useState(false);
  const [isUnisexOpen, setIsUnisexOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const handleShopAllDropdownClick = (e) => {
    e.preventDefault();
    setIsShopAllOpen(!isShopAllOpen);
    setIsUnisexOpen(false);
  };

  const handleUnisexDropdownClick = (e) => {
    e.preventDefault();
    setIsUnisexOpen(!isUnisexOpen);
    setIsShopAllOpen(false);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  const [color, setColor] = useState(false)
  const changeColor =() => {
      if(window.scrollY >= 100) {
          setColor(true)
      } else {
          setColor(false)
      }
  }

  window.addEventListener('scroll', changeColor)

  const style = {
    background: isScrolled ? "#000" : "#fff",
  };

  return (
    <div
      className={
        color
          ? "hidden md:flex fixed z-50 bg-[rgba(0,0,0, .85)] opacity-90 w-[100%] p-10 "
          : "hidden md:flex fixed z-50 p-10 w-[99%]"
      }
    >
      <div className="lg:hidden absolute left-3">
        <button className=" hover:text-gray-400 focus:outline-none ">
          <h1 className="text-white text-2xl font-bold">
            <Link to="/">THE CONTINENTAL</Link>
          </h1>
        </button>
      </div>
      <nav
        className={`fixed top-5 md:top-0 md:left-0 md:right-0 left-72 z-50 flex justify-between items-center ${
          color ? "md:bg-[#000000d9]" : "bg-transparent"
        } text-white py-4 px-6`}
      >
        <div className="flex items-center"></div>
        <div className="absolute flex m-2">
           <a href="https://pp.events/aaOmBZ9m">

          <img
            src={V2}
            alt={''}
            className="rounded-full mx-2 cursor-pointer h-10 w-10"
            />
            </a>
        <button className=" hover:text-gray-400 focus:outline-none ">
          <h1 className="text-white text-2xl font-bold">
            <Link to="/">THE CONTINENTAL</Link>
          </h1>
        </button>
          {/* <button
            className="text-white z-50 hover:text-gray-400 focus:outline-none"
            onClick={toggleDropdown}
          >
            {isOpen ? (
              <AiOutlineClose className="h-8 w-8 z-50" />
            ) : (
              <AiOutlineMenu className="h-9 w-9 z-50" />
            )}
          </button> */}
         
        </div>
     
        <div className="lg:flex hidden  items-center">
          <ul className="flex space-x-20">
         
          <li className="font-bold text-lg">
              <Link to="/">Home</Link>
            </li>
            {/* <li className="font-bold text-lg">
              <Link to="https://pp.events/aaOmBZ9m">Female</Link>
            </li>
            <li className="font-bold text-lg">
              <Link to="https://pp.events/aaOmBZ9m">Male</Link>
            </li>
            <li className="font-bold text-lg">
              <Link to="https://pp.events/aaOmBZ9m">Contact Us</Link>
            </li> */}
            {/* {

              user ?

              <li className="font-bold text-lg">
              <Link to="https://pp.events/aaOmBZ9m">Subscriptions</Link>
            </li> 
            : null
            } */}
            {

              user ?

              <li className="font-bold text-lg">
              <Link to="/Hook ">Hook Up</Link>
              {/* <Link to="/order">Order</Link> */}
            </li> 
            : null
            }
            {

              user ?

              <li className="font-bold text-lg">
              <Link to="/TNC">Terms And Conditions</Link>
              {/* <Link to="/order">Order</Link> */}
            </li> 
            : null
            }
            {/* {

              user ?

              <li className="font-bold text-lg">
              <Link to="Wallet">Transactions</Link>
            </li> 
            : null
            } */}
                 {/* { user ? 
            <li className="font-bold text-lg">
              <Link to="/Contact">Messages</Link>
            </li>

            : null

            } */}
                 { user ? 
            <li className="font-bold text-lg">
              <Link to="/Contact">Contact Us</Link>
            </li>

            : null

            }
            {/* <li className="font-bold text-lg">
              <a href="/shop">Connect</a>
            </li> */}
            <li className="font-bold text-lg">
              <Google />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
