import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
  authDomain: "agency-b5b65.firebaseapp.com",
  projectId: "agency-b5b65",
  storageBucket: "agency-b5b65.appspot.com",
  messagingSenderId: "30425467879",
  appId: "1:30425467879:web:aef3dadb4967b61c258b42",
  measurementId: "G-1QKVN1SZDF"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in
    console.log("User is signed in:", user);
  } else {
    // User is signed out
    console.log("User is signed out");
  }
});

const app = initializeApp(firebaseConfig);

export default firebase;
export const db = getFirestore(app)
export const storage=getStorage(app)