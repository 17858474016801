import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { AiOutlineLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";

const POST = ({ handlePost, setPost, post, CSuser }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [caption, setCaption] = useState('');
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState('');
  const [commentText, setCommentText] = useState('');
  const [posts, setPosts] = useState([]);
  const [openPostId, setOpenPostId] = useState(null);

  const handleCom = (postId) => {
    setOpenPostId(postId === openPostId ? null : postId);
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    setMediaFile(file);

    // For previewing the media before uploading
    const reader = new FileReader();
    reader.onload = (event) => {
      setMediaUrl(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    if (!caption || !mediaFile) {
      console.error('Please provide a caption and select a media file.');
      return;
    }

    const storageRef = firebase.storage().ref();
    const mediaRef = storageRef.child(mediaFile.name);
    await mediaRef.put(mediaFile);
    const url = await mediaRef.getDownloadURL();

    setMediaUrl(url);

    const post = {
      caption: caption,
      userId: CSuser.uid,
      mediaUrl: url,
      likes: 0,
      comments: [],
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };

    const db = firebase.firestore();
    await db.collection('posts').add(post);

    setCaption('');
    setMediaFile(null);
    setMediaUrl('');
    handlePost();
  };

  const handleLike = async (postId) => {
    try {
      const db = firebase.firestore();
      const postRef = db.collection('posts').doc(postId);
      
      if (!currentUser) {
        console.error('User is not authenticated.');
        return;
      }
  
      const likeRef = db.collection('likes').doc(`${postId}_${currentUser.uid}`);
  
      const likeDoc = await likeRef.get();
  
      if (likeDoc.exists) {
        await likeRef.delete();
        await postRef.update({ likes: firebase.firestore.FieldValue.increment(-1) });
      } else {
        await likeRef.set({ postId: postId, userId: currentUser.uid });
        await postRef.update({ likes: firebase.firestore.FieldValue.increment(1) });
      }
  
      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === postId ? { ...post, likes: post.likes + (likeDoc.exists ? -1 : 1) } : post
        )
      );
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  

  const handleComment = async (postId) => {
    try {
      const db = firebase.firestore();
      await db.collection('comments').add({
        postId: postId,
        userId: CSuser.uid,
        comment: commentText,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === postId ? { ...post, comments: [...post.comments, commentText] } : post
        )
      );
      setCommentText('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const db = firebase.firestore();
        const snapshot = await db.collection('posts').get();
        const postsData = await Promise.all(snapshot.docs.map(async doc => {
          const postData = doc.data();
          const commentsSnapshot = await db.collection('comments').where('postId', '==', doc.id).get();
          const commentsData = commentsSnapshot.docs.map(commentDoc => commentDoc.data().comment);
          return {
            id: doc.id,
            ...postData,
            comments: commentsData
          };
        }));
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className='pt-0'>
      <div className={!post ? ' bg-gray-800 absolute top-10 p-5 alignself-center' : "hidden"}> 
        <h2 className='text-center my-5'>Create a New Post</h2>
        <div>
          <input className='w-80 h-12 p-2 m-2 outline-blue-300' placeholder='Caption' type="text" value={caption} onChange={handleCaptionChange} />
        </div>
        <div>
          <input className='w-80 bg-white h-12 p-2 m-2 outline-blue-300' type="file" accept="image/, video/" onChange={handleMediaChange} />
        </div>
        {mediaUrl && (
          <div>
            {mediaFile.type.startsWith('image/') ? (
              <img src={mediaUrl} alt="Uploaded Image" style={{ maxWidth: '20%' }} />
            ) : (
              <video controls>
                <source src={mediaUrl} type={mediaFile.type} />
              </video>
            )}
          </div>
        )}
        <button className='bg-green-400 p-2 m-4 rounded-md text-white font-bold hover:bg-green-500' onClick={handleUpload}>Upload Post</button>
        <button className='bg-green-400 p-2 m-4 rounded-md text-white font-bold hover:bg-green-500' onClick={handlePost}>Close</button> 
      </div>

      <div className=' grid justify-center'>
        <h2 className='my-10 font-bold'>Posts</h2>
        {posts.map(post => (
          <div className='w-[700px] border-2 p-5 mb-10 h-[500px] border-gray-200 rounded-md' key={post.id}>
            <div className='flex items-center gap-5'>
              <img src={post.mediaUrl} alt="User Avatar" style={{ width: '50px', height: '50px', borderRadius: '50%', padding:0, }} />
              <span>{post.caption || post.user.email}</span>
            </div>

            <p className='font-bold p-1'>{post.caption}</p>
            {post.mediaUrl.endsWith('.mp4') || post.mediaUrl.endsWith('.mov') ? (
              <video className='w-[100%] h-[70%] rounded-lg' controls>
                <source src={post.mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img className='w-[100%] h-[70%] rounded-lg' src={post.mediaUrl} alt="" />
            )}

            <div className='flex flex-row gap-10 my-3 '>
              <AiOutlineLike onClick={() => handleLike(post.id)} size={24} />
              <FaComment onClick={() => handleCom(post.id)} size={24} />
            </div>  
            <p>{post.likes || 0} {post.likes >= 2 ? "Likes" : "like" }</p>

            <div className={openPostId === post.id ? "bg-gray-700 absolute p-4 h-96 w-[50%] z-50 " : "hidden" }>
              <h3>Comments</h3>
              <input className='p-2' type="text" placeholder="Add a comment" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
              <button className='p-2 bg-blue-500 ml-4 text-white font-bold rounded-lg' onClick={() => handleComment(post.id)}><IoIosSend size={24} /></button>
              <ul className='p-2 '>
                {post.comments.map((comment, index) => (
                  <li className='border-bottom-2 border-gray-200 font-light font-xl' key={index}>{comment}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default POST;
