import React, { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import { BsCartPlus } from "react-icons/bs";
import firebase from "firebase/compat/app";
import firebaseConfig from "./firebaseconfig";
import "firebase/compat/firestore";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Nav from "./component/Nav";
// import Homes from "./Homes";
import MobileNav from "./Components/MobileNav";

const New = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState([]);
  const { addItem } = useCart();

  useEffect(() => {
    async function getProductData() {
      // Initialize Firebase
      const firebaseConfig = {
        apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
        authDomain: "agency-b5b65.firebaseapp.com",
        projectId: "agency-b5b65",
        storageBucket: "agency-b5b65.appspot.com",
        messagingSenderId: "30425467879",
        appId: "1:30425467879:web:aef3dadb4967b61c258b42",
        measurementId: "G-1QKVN1SZDF"
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const docRef = db.collection("Products")
    //   .doc(productId);
      const doc = await docRef.get();
      const data = doc.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProductData(data);


    //   if (doc.exists) {
    //     const data = doc.data();
    //     const colors =
    //       typeof data.color === "string" ? data.color.split(",") : [];
    //     setProductData({ id: doc.id, ...data, colors });
    //   } else {
    //     console.log("No such document!");
    //     setProductData(null);
    //   }
    }

    getProductData();
  }, [productId]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="py-5 w-screen bg-[url('https://firebasestorage.googleapis.com/v0/b/nextberries-9957f.appspot.com/o/special%2FWEBSITE%20PROTOTYPE.png?alt=media&token=045aa610-8b03-468d-9166-5054bbb018f9')] bg-no-repeat md:bg-cover bg-center bg-cover bg-[#656564] text-white pt-20">
      <div className="justify-content-center mt-5">
        <div xs={10} md={7} lg={5} className="p-0">
            <Carousel showArrows={true} showThumbs={true}>
              {productData.map((Cloth, index) => (
                  <div
                  key={index}
                  className="lg:w-[25%] lg:flex w-[80%] ml-[10%] lg:ml-[30%] border-2 border-white p-2 rounded-lg text-center"
                  >
                  <img src={Cloth.product_image} alt={`Cloth ${index + 1}`} />
        <div xs={10} md={7} lg={7} className="product-details p-5 md:ml-[40%]">
          <img src={Cloth.qrcode} alt={`Cloth ${index + 1}`} className=" right-0 self-start rounded-lg" />
          <h1 className="text-2xl">{Cloth.title}</h1>
          <button
            onClick={() => addItem(Cloth)}
            className="bg-dark-primary mt-10 mb-10 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "0", border: 0 }}
          >
            <BsCartPlus size="1.8rem" className="ml-10" />
            Add to cart
          </button>
          <br />
          <b className="text-dark-primary h4 mt-3 d-block">
            $: {Cloth.price}
          </b>
          <br />
          {/* <strike className="text-red-300 font-extralight mt-3">
            $. {Cloth.old_price}
        </strike> */}
          <br />
          <b className="h5">5.0 ⭐ ⭐ ⭐ ⭐ ⭐ Rated</b>
          <p
            className="mt-3 mb-5 text-xl"
            style={{ opacity: "0.8", fontWeight: "400" }}
          >
            {Cloth.descriprion}
          </p>
          <Link
          to={'/Customization'}
            className=" mb-10 p-3 rounded-2xl bg-white  hover:bg-gray-600 hover:text-white duration-500  text-gray-600"
            style={{ borderRadius: "0", border: 0 }}
          >
            {/* <BsCartPlus size="1.8rem" className="ml-10" /> */}
            Customize
          </Link>
          {/* <p className="mt-3 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            Colors:
            {productData.color.map((color, index) => (
                <span key={index}>
                {color.trim()}
                {index !== productData.product_color.length - 1 ? ", " : ""}
              </span>
            ))}
            {productData.product_color}
          </p> */}
          {/* <p className="mt-3 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            ${productData.shipping_fee}
          </p> */}
        </div>
                </div>
              ))}
            </Carousel>
              {/* {productData.product_image && productData.product_image.length > 0 ? (
          ) : (
            <div>No images available</div>
          )} */}
        </div>
      </div>
      <Nav />
      <MobileNav />
      {/* <Homes /> */}
    </div>
  );
};

export default New;
