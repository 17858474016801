import React, { useEffect, useState } from "react";
// import Home from "./Home";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaShoppingCart, FaUserCog } from "react-icons/fa";
import { useCart } from "react-use-cart";
import { Link } from "react-router-dom";
import firebase from "../firebaseconfig";
import { BiShoppingBag } from "react-icons/bi";
// import Auth from "./Auth";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import firebase from "firebase/compat/app";
import "firebase/compat/firestore";



// const firebaseConfig = {
//   // Your Firebase config
//   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
//   authDomain: "nextberries-cb5b9.firebaseapp.com",
//   projectId: "nextberries-cb5b9",
//   storageBucket: "nextberries-cb5b9.appspot.com",
//   messagingSenderId: "44749615279",
//   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
//   measurementId: "G-9QZ27VRVHL",
// };

// firebase.initializeApp(firebaseConfig);

// const firestore = firebase.firestore();


function Google({me}) {
    const { isEmpty, totalItems } = useCart();

  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const [user, setUser] = useState(null);


  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const [CSuser, setCSUser] = useState();
  // fetch the user data from firebase
  useEffect(() => {
    const subscriber = firebase.firestore()
        .collection('Users')
        .doc(firebase.auth()?.currentUser?.uid)
        .onSnapshot(documentSnapshot => {
            setCSUser(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
    }, []);


  const handleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const { displayName, photoURL } = result.user;
        const userData = { displayName, photoURL };

        // Store user data in local storage
        localStorage.setItem("userData", JSON.stringify(userData));

        setUser(userData);

        if(CSuser?.name === 'Your DisplayName'){
          
          firebase.firestore()
          .collection('Users')
          .doc(result.user.uid)
          .set({
              uid: result.user.uid, displayName: result.user.displayName, 
              phoneNumber: result.user.phoneNumber || +234000000000000, photoURL: result.user.photoURL,
               name: 'Your DisplayName', displayVideo: 'https://firebasestorage.googleapis.com/v0/b/agency-b5b65.appspot.com/o/WhatsApp%20Video%202023-10-08%20at%2020.39.16.mp4?alt=media&token=b1a08bf9-b009-4398-bfb9-4c4154b55bce&_gl=1*2oajj3*_ga*MjAyMDYwMzM5NC4xNjk2MTc1MTA3*_ga_CW55HF8NVT*MTY5Njc5Mjc2MS4xNy4xLjE2OTY3OTQwODQuNDMuMC4w', 
               displayPhoto: 'https://firebasestorage.googleapis.com/v0/b/agency-b5b65.appspot.com/o/p22.jpeg?alt=media&token=ff3b3d39-a920-475e-a262-aeeef422611a&_gl=1*42apiz*_ga*MjAyMDYwMzM5NC4xNjk2MTc1MTA3*_ga_CW55HF8NVT*MTY5NjU0MDIzMC45LjEuMTY5NjU0MDM1Ni4zNi4wLjA.',
               email: result.user.email,
             badge: false,
             offlineFee: 50, onlineFee: 50, onlineRules: '1hr Sex Chat With Text, Images And Video', location: 'Lagos',
          createdAt: new Date(), balance: 0, Show: false,
        })

        }
        

        
      })
      .catch((error) => {
        console.error(error);
      });

  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Remove user data from local storage
        localStorage.removeItem("userData");

        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div 
    className="text-white z-50"
    // className="fixed z-50 md:top-20 top-10 left-[5%] md:left-[95%]"
    >

{ user ?
<>
{
  me === 'me' ?
  <div 
  className={
    `cursor-pointer w-72 text-center  border-white z-50 border-0 p-3 bg-[#00aff0] text-[#fff] rounded-lg`
}
  
  onClick={handleNav} >
  <Link to={'/Profile'} className=" px-6 text-sm py-1 ">

  Make Money With Your Profile
  </Link>
</div >
:
    <div 
    
    className={
      me === 'me' ? 
      `cursor-pointer w-52 text-center lg:ml-[49%] ml-[45%] z-50 `
      : 
      `cursor-pointer `}    
    >

    {/* <FaUserCog color="white" size={25} onClick={handleNav} /> */}
    <Link 
    // to={'/Profile'}
    
    onClick={handleNav}
     >

    <img
            src={user.photoURL}
            alt={user.displayName}
            className={
              me === 'me' ? 
              `rounded-full w-10 h-10`
              :
              `rounded-full w-8 h-8`}
            />
            </Link>
    {/* <i className="hidden md:flex w-2 h-2 top-5 right-8 bg-red-600 rounded-full absolute"></i> */}
    </div>
}
</>

    :
    <div 
    className={
      me === 'me' ? 
      `cursor-pointer w-52 text-center   border-white z-50 border-2`
      : 
      `cursor-pointer w-52 text-center  border-white z-50 border-2`}
    
    onClick={handleNav} >
    <p className=" px-6 py-1 ">

    Go Inside
    </p>
  </div >
}




<>

    {
         !nav ?
    <div
      className={
        !nav
          ? "hidden md:flex w-[27%] fixed items-center right-10 text-black bg-white rounded-md md:mt-5 mt-10 shadow-2xl drop-shadow-2xl p-10"
          : "null"
      }
    >
      {user ? (
        <div className="text-center gap-10 flex-row justify-center">
          <img
            src={user.photoURL}
            alt={user.displayName}
            className="rounded-full ml-10 mb-3"
          />
          <p>{user.email}</p>
          <p>Welcome, {user.displayName}!</p>
          <button
// onClick={event =>  window.location.href='/Order'}
            className=" bg-slate-200 z-50 m-2 border-2 p-1  text-sm rounded-lg"
          >
            <Link to={'/Profile'}>
                      <p className="px-6 py-1 flex-row flex items-center" >
                      Make Money With Your Profile
                                  </p>
            </Link>
          </button>
          <button
            onClick={handleSignOut}
            className=" bg-slate-200 z-50 border-2 p-1  text-sm rounded-lg"
          >
                      <p className="px-6 py-1 flex-row flex items-center" >
            Sign Out
            </p>
          </button>
        </div>
      ) : (
        <button onClick={handleSignIn} className="flex-row flex text-center bg-slate-200 z-50 border-2 ">
          <p className="px-6 py-1 flex-row flex items-center" >
          <FcGoogle size={40} />  Continue with Google
            </p>
        </button>
      )}
      {/* <Link
        to={"/shop"}
        className="ml-24 mt-10 rounded-lg border-2 border-green-500 p-1 "
      >
        Visit Shop
      </Link> */}
    </div>
    :
    null

    }



    {/* Mobile Logins */}

    {
         !nav ?
    <div
      className={
        !nav
          ? "md:hidden absolute items-center text-black bg-white rounded-md md:mt-5 mt-10 shadow-2xl drop-shadow-2xl p-10"
          : "null"
      }
    >
      {user ? (
        <div className="text-center gap-10 flex-row justify-center">
          <img
            src={user.photoURL}
            alt={user.displayName}
            className="rounded-full ml-10 mb-3"
          />
          <p>{user.email}</p>
          <p>Welcome, {user.displayName}!</p>
          <button
// onClick={event =>  window.location.href='/Order'}
            className=" bg-slate-200 z-50 m-2 border-2 text-sm p-1 rounded-lg"
          >
            <Link to={'/Profile'}>
                      <p className="px-6 py-1 flex-row flex items-center" >
            Make Money With Your Profile
            </p>
            </Link>
          </button>
          <button
            onClick={handleSignOut}
            className=" bg-slate-200 z-50 border-2 p-1  text-sm rounded-lg"
          >
                      <p className="px-6 py-1 flex-row flex items-center" >
            Sign Out
            </p>
          </button>
        </div>
      ) : (
        <button onClick={handleSignIn} className="flex-row flex text-center bg-slate-200 z-50 border-2 ">
          <p className="px-6 py-1 flex-row flex items-center" >
          <FcGoogle size={40} />  
             Continue with Google
            </p>
        </button>
      )}
      {/* <Link
        to={"/shop"}
        className="ml-24 mt-10 rounded-lg border-2 border-green-500 p-1 "
      >
        Visit Shop
      </Link> */}
    </div>
    :
    null

    }
    </>

  </div>
    )
}

export default Google