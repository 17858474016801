import React, { useEffect, useState } from "react";
// import { useThemeHook } from "../GlobalComponents/ThemeProvider";
import { BiSearch } from "react-icons/bi";
import SearchFilter from "react-filter-search";
import ProductCard from "./ProductCard";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "../firebaseconfig";
import S1 from "../Assets/p1.mp4";
import S2 from "../Assets/p2.mp4";
import S3 from "../Assets/p3.mp4";
import S4 from "../Assets/p4.mp4";
import S5 from "../Assets/p5.mp4";
import S6 from "../Assets/p6.mp4";
import S7 from "../Assets/p7.mp4";
import S8 from "../Assets/p8.mp4";
import S9 from "../Assets/p9.mp4";
import S10 from "../Assets/p10.mp4";
import Google from "./Google";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();
const Homes = () => {
  // const [theme] = useThemeHook();
  const [searchInput, setSearchInput] = useState("");
  const [productData, setProductData] = useState([]);
  // const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function getResponse() {
      // Initialize Firebase
      const firebaseConfig = {
        apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
        authDomain: "agency-b5b65.firebaseapp.com",
        projectId: "agency-b5b65",
        storageBucket: "agency-b5b65.appspot.com",
        messagingSenderId: "30425467879",
        appId: "1:30425467879:web:aef3dadb4967b61c258b42",
        measurementId: "G-1QKVN1SZDF"
      };
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const collection = db.collection("Users");
      const snapshot = await collection.get();
      const Products = [];

      snapshot.forEach((doc) => {
        Products.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setProductData(Products);
    }

    getResponse();
  }, []);

  const Vid = [
    {
        vid: S9,
        name: 'Hot Pie',
        uid: 33
    },
    {
        vid: S2,
        name: 'Angel',
        uid: 33

    },
    {
        vid: S3,
        name: 'Mary Ann',
        uid: 33

    },
    {
        vid: S1,
        name: 'Princess',
        uid: 33

    },
    {
        vid: S5,
        name: 'Queen',
        uid: 33

    },
    {
        vid: S6,
        name: 'Sky Love',
        uid: 33

    },
    {
        vid: S7,
        name: 'Anabelle',
        uid: 33

    },
    {
        vid: S8,
        name: 'Golden',
        uid: 33

    },
    {
        vid: S4,
        name: 'Fish',
        uid: 33


    },
    {
        vid: S10,
        name: 'Faith',
        uid: 33

    },
  ]

  return (
    <div className="pt-20 items-center justify-center h-[100%] w-[100%]">
      <div className="text-center">
        <div className="relative ">

          <div className="z-50 items-center justify-center flex">

          <Google me={'me'} />
          </div>
        
        <h1 className="font-bankGothic items-center justify-center flex text-center z-50 pt-6 text-white text-lg">
          Make money with your content and hook up Guaranty of 2 weeks return policy on any transaction.
          Note: Make all payment on the site
        </h1>
          <BiSearch
            size="30"
            className="absolute md:left-[41%] left-[30%] z-20 mt-[50px]"
            color="#00aff0"
          />
          <input
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="text-gray-50 h-12 md:w-96 bg-[#444] rounded-md m-10 text-center outline-none text-xl"
          />
        </div>
      </div>
      <SearchFilter
        value={searchInput}
        data={productData}
        renderResults={(results) => (
          <h1 className="grid grid-cols-2 md:grid-cols-4">
            {results.map((item, i) => (
              <ProductCard data={item} key={i} />
            ))}
          </h1>
        )}
      />
    </div>
  );
};

export default Homes;
