import React, { useEffect, useState } from "react";
// import Home from "./Home";
import { MdImageAspectRatio, MdOutlineShoppingCart } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaShoppingCart, FaUserCog } from "react-icons/fa";
import { useCart } from "react-use-cart";
import { Link, useNavigation } from "react-router-dom";
import firebase, { db, storage } from "../firebaseconfig";
import { BiCheck, BiPlus, BiShoppingBag } from "react-icons/bi";
// import Auth from "./Auth";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Nav from "../component/Nav";
import MobileNav from "./MobileNav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import S6 from "../Assets/p6.mp4";
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import POST from "./POST";


// import { MenuItem, Select } from "@mui/material";



// const firebaseConfig = {
//   // Your Firebase config
//   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
//   authDomain: "nextberries-cb5b9.firebaseapp.com",
//   projectId: "nextberries-cb5b9",
//   storageBucket: "nextberries-cb5b9.appspot.com",
//   messagingSenderId: "44749615279",
//   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
//   measurementId: "G-9QZ27VRVHL",
// };

// firebase.initializeApp(firebaseConfig);

// const firestore = firebase.firestore();


function Profile() {
    const { isEmpty, totalItems } = useCart();
    const [post, setPost] = useState("false");


  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();

 const handlePost  = () => {
    setPost(!post)
  }

  // fetch the user data from firebase
  useEffect(() => {
    const subscriber = firebase.firestore()
        .collection('Users')
        .doc(firebase.auth()?.currentUser?.uid)
        .onSnapshot(documentSnapshot => {
            setCSUser(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
    }, []);

    console.log(CSuser)

  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut();
  };


    const handleSubmit = async (e) => {



      if (file) {
        alert("You're about to update your profile with a new Cover Picture!")
        const storageRef = ref(storage, `/passport/${file.name}`)
  
        const uploadTask = uploadBytesResumable(storageRef, file)
  
        uploadTask.on("state_changed", (snapshot) => {
  
          const prog = Math.round(
  
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(prog)
  
        }, (err) => console.log(err), () => {
  
          getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
  
            const passportURL = url
            // const DateofPosting = new Date(dop)
  
  
            await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {
            displayPhoto: passportURL || CSuser?.displayPhoto,
            name: Name || CSuser?.name, 
            offlineFee: OfflinePrice || CSuser?.offlineFee,
            onlineFee: OnlinePrice || CSuser?.onlineFee,
            location: Location || CSuser?.location,
            Show: true,
            phoneNumber: Num || CSuser?.phoneNumber


            }).then(()=>{
  
              toast.success("Updated successfully!")
              // window.location.reload()
  
            }).catch((err)=>{
              toast.error("An Error occured")
              // window.location.reload()
            })
  
          })
  
        })
  
      } else if(fileVideo) {
        alert("You're about to update your profile with a new display video")
        const storageRef = ref(storage, `/passport/${fileVideo.name}`)
  
        const uploadTask = uploadBytesResumable(storageRef, fileVideo)
  
        uploadTask.on("state_changed", (snapshot) => {
  
          const prog = Math.round(
  
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(prog)
  
        }, (err) => console.log(err), () => {
  
          getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
  
            const passportURL = url
            // const DateofPosting = new Date(dop)
  
  
            await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {

            displayVideo: passportURL || CSuser?.displayVideo,
            name: Name || CSuser?.name, 
            offlineFee: OfflinePrice || CSuser?.offlineFee,
            onlineFee: OnlinePrice || CSuser?.onlineFee,
            location: Location || CSuser?.location,
            Show: true,
            phoneNumber: Num || CSuser?.phoneNumber


            }).then(()=>{
  
              toast.success("Updated successfully!")
              // window.location.reload()
  
            }).catch((err)=>{
              toast.error("An Error occured")
              // window.location.reload()
            })
  
          })
  
        })
  
      } else if (file, fileVideo === null){
  
  const Run=async()=>{
    alert("You're about to update your profile data!")
    // const DateofPosting = new Date(dop)
    await updateDoc(doc(db, "Users", firebase.auth().currentUser.uid), {
      name: Name || CSuser?.name, 
      offlineFee: OfflinePrice || CSuser?.offlineFee,
      onlineFee: OnlinePrice || CSuser?.onlineFee,
      location: Location || CSuser?.location,
      Show: true,
      phoneNumber: Num || CSuser?.phoneNumber

  
    }).then(()=>{
  
      toast.success("Updated successfully!")
      // window.location.reload()
  
    }).catch((err)=>{
      toast.error("An Error occured")
      // window.location.reload()
    })
  
  }
  Run()
  }
  
  
    };

   


  const [file, setFile] = useState(null);
  const [fileVideo, setFileVideo] = useState(null);
  const [Name, setName] = useState('');
  const [OfflinePrice, setOfflinePrice] = useState(0);
  const [OnlinePrice, setOnlinePrice] = useState(0);
  const [Location, setLocation] = useState('');
  const [Num, setNum] = useState();
  const [progress, setProgress] = useState(0)


  return (
    <div 
    className="bg-[#656564] h-[100%] w-screen lg:p-32 p-10 lg:pt-28 pt-24 text-center items-center justify-center"
    // className="fixed z-50 md:top-20 top-10 left-[5%] md:left-[95%]"
    >

<Link onClick={handleSubmit} className="bg-[#00aff0] flex justify-center items-center fixed lg:right-10 right-5 text-[#fff] text-center text-sm rounded-lg p-4 px-5">
                 Submit <BiCheck />
                </Link>
<Link onClick={handlePost} className="bg-[#00aff0] flex justify-center items-center mt-20 fixed lg:right-10 right-5 text-[#fff] text-center text-sm rounded-lg p-4 px-5">
                 Create Post <BiPlus />
                </Link>
      <h1 className="text-white text-5xl text-center mb-5">
       {CSuser?.name === 'Your DisplayName' ? 'Please Change' : 'Hello'} {CSuser?.name}
      </h1>
      <h1 className="bg-[#333] text-[#fff] text-center text-sm ml-2 mb-5 rounded-lg p-4"  >
       Balance $ {CSuser?.balance}
      </h1>

      <div  className="lg:flex grid text-center items-center justify-center">

<div>
<p className="p-3  ">
 Change DisplayName
</p>
      <input type="text" className="bg-[#fff] text-[#000] text-center text-sm ml-2 mb-0 rounded-lg p-4"
      placeholder={CSuser?.name === 'Your DisplayName' ? 'Please Change Your DisplayName' : 'Change DisplayName...'}
      // placeholder="Change DisplayName..."
      onChange={(e)=>setName(e.target.value)}
/>
      </div>
<div>

<p className="p-3  ">
 Change Phone Number
</p>
      <input type="number" className="bg-[#fff] text-[#000] text-center text-sm ml-2 mb-0 rounded-lg p-4"
      placeholder={ CSuser?.phoneNumber}
      onChange={(e)=>setNum(e.target.value)}
/>
      </div>

<div>

<p className="p-3  ">
 Change Your Location
</p>
      <input type="text" className="bg-[#fff] text-[#000] text-center text-sm ml-2 mb-0 rounded-lg p-4"
      placeholder={ CSuser?.location}
      onChange={(e)=>setLocation(e.target.value)}
/>
      </div>
</div>

{/* <div className="formInput">
                <label htmlFor="file" className="bg-[#333] cursor-pointer text-[#fff] text-sm rounded-lg p-5">
                Create Post For Your Subscribers
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                >

                </input>
              </div> */}

       <div className="lg:flex justify-center mb-20 mt-10">

          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : CSuser?.displayPhoto
              }
              alt=""
              className="h-60 w-36 lg:ml-10 ml-12 mb-5 rounded-lg"
            />
              {/* <div className="">
                <label htmlFor="file" className="bg-[#fff] cursor-pointer text-[#000] text-sm ml-2 rounded-lg p-3">
                Change Cover Picture
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                >

                </input>
              </div> */}
              <input type="file" className="bg-[#fff] text-[#000] text-center text-sm rounded-lg p-3"
      placeholder="Change Cover Picture"
      onChange={(e)=>setFile(e.target.files[0])}
/>
          </div >


          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <video
              src={
                fileVideo
                  ? URL.createObjectURL(fileVideo)
                  : CSuser?.displayVideo
              }
              autoPlay
              loop
              muted
              alt=""
              className="h-60 w-36 lg:ml-10 ml-12 mb-5 rounded-lg"
            />


<input type="file" className="bg-[#fff] text-[#000] text-center text-sm  rounded-lg p-3"
      placeholder="Change Display Video."
      onChange={(e)=>setFileVideo(e.target.files[0])}
/>
          </div >



          {/* <div className="border-2 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className="h-76 w-36  p-3 mb-5 bg-[#ccc] text-[#000] rounded-lg"
            > 
            <p>
              $20
            </p>
             </div>

             <p className="bg-[#ccc] text-[#000] text-center text-sm rounded-lg p-4">
                 Subscribtion
                </p>
        
          </div > */}
          
          {/* <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className=" p-3 mb-2 bg-[#fff] text-[#000] rounded-lg"
            > 
            <p>
              ${CSuser?.onlineFee}
            </p>
             </div>
             
                <input type="number" className="bg-[#fff] text-[#000] text-center text-sm mb-5 rounded-lg p-4"
      placeholder="Online Hookup Price..."
      onChange={(e)=>setOnlinePrice(e.target.value)}
/>
<br />
<p>
             {CSuser?.onlineRules}
            </p>
<br />
                <Link className="bg-[#fff] text-[#000] text-center text-sm rounded-lg p-4">
                 Remove
                </Link>
          </div > */}
          
          <div className="border-2 w-72 rounded-lg m-3 p-5 items-center justify-center grid">
            <div
              alt=""
              className=" p-3 mb-2 bg-[#fff] text-[#000] rounded-lg"
            > 
            <p>
              ${CSuser?.offlineFee}
            </p>
             </div>
             
                <input type="number" className="bg-[#fff] text-[#000] text-center ml-2 text-sm mb-5 rounded-lg p-4"
      placeholder="Offline Hookup Price..."
      onChange={(e)=>setOfflinePrice(e.target.value)}
/>
<br />
<p>
             Meet And Fuck For 1hr
             <br />
             Venue: Hotel
            </p>
<br />
                <Link className="bg-[#fff] text-[#000] text-center text-sm rounded-lg p-4">
                 Remove
                </Link>
          </div >
     


         
          {/* <div className="right">
            <div className="form">
            


              <div className="formInput">
                <label>First Name</label>
                <input type={"text"} placeholder={"First Name"} onChange={(e) => setFirstname(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Middle Name</label>
                <input type={"text"} placeholder={"Middle Name"} onChange={(e) => setLastname(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Last Name</label>
                <input type={"text"} placeholder={"Last Name"} onChange={(e) => setLastname(e.target.value)} />
              </div>
              <div className="formInput">
                <label>(Pew) & Display Name <span style={{color: 'red'}}>*</span></label>
                <input type={"text"} placeholder={"Pew & Display Name"} onChange={(e) => setAllNames(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Date Of Birth <span style={{color: 'red'}}>*</span></label>
                <input type={"date"} placeholder={"Date Of Birth"} onChange={(e) => setAge(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Date of Baptism <span style={{color: 'red'}}>*</span></label>
                <input type={"date"} placeholder={"Date of Baptism"} onChange={(e) => setDob(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Date of Odination <span style={{color: 'red'}}>*</span></label>
                <input type={"date"} placeholder={"Date of Odination"} onChange={(e) => setDoO(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Date of Posting <span style={{color: 'red'}}>*</span></label>
                <input type={"date"} placeholder={"Date of Posting"} onChange={(e) => setDop(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Health Status</label>
                <input type={"text"} placeholder={"Health Status"} onChange={(e) => setHealth(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Marital Status</label>
                <Select
                label="Marital Status"
                  onChange={(txt) => setMarital(txt.target.value)}
                >
                  <MenuItem value={'Single'}>Single</MenuItem>
                  <MenuItem value={'Married'}>Married</MenuItem>
                  <MenuItem value={'Divorced'}>Divorced</MenuItem>
                  <MenuItem value={'Separated'}>Separated</MenuItem>
                </Select>
              </div>

              <div className="formInput">
                <label>Nationality</label>
                <input type={"text"} placeholder={"Nationality"} onChange={(e) => setNationality(e.target.value)} />
              </div>
              <div className="formInput">
                <label>State of Origin</label>
                <Select
                  onChange={(txt) => setState(txt.target.value)}
                >
                  {StateData.map((state,index)=>(
                        <MenuItem value={state.state}>{state.state}</MenuItem>
                  ))}
                  <hr/>
                  <MenuItem value={"Others"}>{"Others"}</MenuItem>
                </Select>
              </div>
              <div className="formInput">
                <label>Priest Phone Number</label>
                <input type={"number"} placeholder={"Priest Phone Number"} onChange={(e) => setPPhone(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Priest Email</label>
                <input type={"text"} placeholder={"Priest Email"} onChange={(e) => setPEmail(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Priest Residence Address</label>
                <input type={"text"} placeholder={"Residence Address"} onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Educational Background</label>
                <input type={"text"} placeholder={"Educational Background"} onChange={(e) => setEdu(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Langauges Spoken</label>
                <input type={"text"} placeholder={"Languages Spoken"} onChange={(e) => setLanguage(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Profession</label>
                <input type={"text"} placeholder={"Profession"} onChange={(e) => setProfession(e.target.value)} />
              </div>
             
              <div className="formInput">
                <label>Priest Place Of Worship</label>
                <input type={"text"} placeholder={"Place Of Worship"} onChange={(e) => setPPOW(e.target.value)} />
              </div>
              <div className="formInput">
                <label>Current Station</label>
                <input type={"text"} placeholder={"Current Station"} onChange={(e) => setStation(e.target.value)} />
              </div>

              <div className="formInput">
                <label>Spiritual Talent(s)</label>
                <input type={"text"} placeholder={"Spiritual Talent(s)"} onChange={(e) => setTalent(e.target.value)} />
              </div>
              
              <div className="formInput">
                <label>Stipend</label>
                <input type={"number"} placeholder={"Stipend"} onChange={(e) => setStipends(e.target.value)} />
              </div>
             
              <div className="formInput">
                <label>Name of Next of Kin</label>
                <input type={"text"} placeholder={"Name of Next of Kin"} onChange={(e) => setNameOfNextkin(e.target.value)} />
              </div>

              <div className="formInput">
                <label>Next of Kin contact (Phone Number)</label>
                <input type={"number"} placeholder={"Next of Kin Contact"} onChange={(e) => setNextkin(e.target.value)} />
              </div>
             
              <div className="formInput">
                <label>Address of Next of Kin</label>
                <input type={"text"} placeholder={"Address of Next of Kin"} onChange={(e) => setAdOfNextkin(e.target.value)} />
              </div>
            
             
              
              <div className="formInput">
                <label>Is this Priest Posted Already?</label>
                <Select
                label="is this priest posted?"
                  onChange={(txt) => setPosted(txt.target.value)}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </div>

              {loading ? (
                <p>Please wait and don't exit this screen!.....</p>
              ) : (
                <button onClick={UploadPost}>Upload</button>
              )}

              <p>image uploading {progress}%</p>
            </div>
          </div> */}


        </div>

         
          <POST setPost={setPost} CSuser={CSuser} setCSuser={setCSUser} post={post} handlePost={handlePost} />

      <Nav />
      <MobileNav />
      <ToastContainer />


  </div>
    )
}

export default Profile