import React from 'react'
import MobileNav from './MobileNav'
import Nav from '../component/Nav'


function TNC() {
  return (
    <div style={{backgroundColor: '#333'}}>
    <h1 style={{padding: 10, paddingTop: 100, textAlign: 'center'}}> Terms &amp; Conditions</h1>
    <div style={{padding: 30}}>

  
     <p>
     These Terms and Conditions (the "Agreement") govern your use of [THE CONTINENTAL] (the "remotevince.com"). By accessing and using this Site, you agree to comply with and be bound by these Terms and Conditions, our Privacy Policy, and any other guidelines or rules provided by us.

                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Eligibility </h2>

                
                <p>
                You must be at least 18 years old to use the Site. By using the Site, you represent and warrant that you are at least 18 years old and that you have the legal capacity to enter into this Agreement.

                </p> 
                
                <h2 style={{padding: 10, textAlign: 'center'}}> User Content </h2>
                
                
                <p>
                Users are solely responsible for the content they post on the Site, including profiles, images, and messages. We reserve the right to monitor and moderate user content, and may remove or disable access to any content that violates our guidelines or applicable laws.
                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Privacy and Data Collection </h2>
                
                
                <p>
                Our Privacy Policy explains how we collect, use, and disclose information from our users. By using the Site, you consent to our collection and use of personal information as outlined in the Privacy Policy.
                                </p>

                <h2 style={{padding: 10, textAlign: 'center'}}> Prohibited Activities </h2>
                
                
                <p>
                Users must not engage in any unlawful or prohibited activities while using the Site. This includes, but is not limited to, harassing other users, transmitting obscene or offensive content, using the Site for commercial purposes, and violating intellectual property rights.
                                                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Intellectual Property </h2>
                
                
                <p>
                All content and materials on the Site, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of [THE CONTINENTAL] and are protected by copyright and other applicable laws
                                                                </p> 
                <h2 style={{padding: 10, textAlign: 'center'}}> Termination </h2>
                
                
                <p>
                We reserve the right to terminate or suspend your access to the Site, without prior notice, for any reason, including without limitation, a breach of these Terms and Conditions.
                                                                                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Disclaimer of Warranties </h2>
                
                
                <p>
                The Site is provided on an "as-is" and "as available" basis. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the Site.
                                                                                                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Limitation of Liability </h2>
                
                
                <p>
                In no event shall [THE CONTINENTAL] or its affiliates be liable for any damages, direct or indirect, arising out of or in connection with the use of the Site.
                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Governing Law </h2>
                
                
                <p>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                                </p> 

                <h2 style={{padding: 10, textAlign: 'center'}}> Changes to Terms and Conditions </h2>
                
                
                <p>
                We reserve the right to modify these Terms and Conditions at any time. Your continued use of the Site after any such changes constitutes your acceptance of the updated Terms and Conditions.

</p> 
                
                
                </div>
                <MobileNav />
                <Nav />
  </div> 
    )
}

export default TNC