import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import firebaseConfig from "../firebaseconfig";
import "firebase/compat/firestore";
import HoverVideoPlayer from 'react-hover-video-player';
import { FaPlay } from "react-icons/fa";
import p22 from "../Assets/p22.jpeg";



const ProductCard = (props) => {
  let { product_image, displayPhoto, displayVideo, price, name, vid, title, uid } = props.data;
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function getResponse() {
      // Initialize Firebase
      const firebaseConfig = {
        apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
        authDomain: "agency-b5b65.firebaseapp.com",
        projectId: "agency-b5b65",
        storageBucket: "agency-b5b65.appspot.com",
        messagingSenderId: "30425467879",
        appId: "1:30425467879:web:aef3dadb4967b61c258b42",
        measurementId: "G-1QKVN1SZDF"
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const collection = db.collection("Users");
      const snapshot = await collection.get();
      const Products = [];

      snapshot.forEach((doc) => {
        Products.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setProductData(Products);
    }

    getResponse();
  }, []);

  const { addItem } = useCart();

  const addToCart = () => {
    addItem(props.data);
  };

  const [Play, setPlay] = useState(false)

  return (
    <div
      key={uid}
      className="text-center rounded-2xl m-5 drop-shadow-2xl shadow-2xl hover:scale-110 duration-500 border-gray-400"
    // onMouseEnter={setPlay(true)}
    // onMouseLeave={setPlay(false)}
    >
    <div
    // onMouseEnter={setPlay(true)}
    // onMouseLeave={setPlay(false)}
    >
      {/* <Link to={firebase.auth().currentUser.uid === uid ? `/Profile` :  `/FProfile/${uid}`}> */}
      <Link to={ `/FProfile/${uid}`}>
        <div>
          <div className="w-[100%]">
                <HoverVideoPlayer
                className=" self-center h-[100%] w-[100%] object-cover"
      videoSrc={displayVideo}
      pausedOverlay={
        <img
          src={displayPhoto}
          alt=""
          className=" self-center h-[100%] w-[100%] object-cover"
        />
      }
      loadingOverlay={
        <div className="loading-overlay">
          <div className="loading-spinner" />
        </div>
      }
    />
 
          </div>
        </div>
      </Link>
      <div className="text-center pb-3 text-lg text-white">
        <h3>Get {name}</h3>
        {/* <h1>
          $. <span className="text-white">{price}</span>
        </h1> */}
        {/* <button onClick={() => addToCart()}>
          <MdOutlineAddShoppingCart
            size="1.8rem"
            className="text-center ml-[30%] text-green-600"
          />
          Add to cart
        </button> */}
      </div>
      </div>
    </div>
  );
};

export default ProductCard;
