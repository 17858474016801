import React from "react";
import Hero from "./Components/Hero";
import Nav from "./component/Nav";
import { Route, Routes } from "react-router-dom";
import Shop from "./Components/Shop";
import Explore from "./Components/Explore";
import Cart from "./Components/Cart";
import ProductDetails from "./Components/ProductDetails";
import Collaborations from "./component/Collaborations";
import AboutUs from "./component/About";
import Order from "./Components/Order";
import DeliveryPolicy from "./Components/DeliveryPolicy";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ProductPro from "./Components/ProductPro";
// import CusCall from "./CusCall";
import New from "./New";
import Profile from "./Components/Profile";
import Contact from "./Components/Contact";
import FProfile from "./Components/FProfile";
import Chat from "./Components/Chat";
import Msg from "./Components/Msg";
import TNC from "./Components/TNC";
import Hook from "./Components/Hook";
// import Homes from "./components/Homes";

const Home = () => {
  return (
    <div style={{backgroundColor: '#656564'}}>
      {/* <Nav /> */}
      <Routes>
        <Route path="/" element={<Shop />} />
        <Route path="/Profile" element={<Profile />} />
        <Route
          path="/FProfile/:uid"
          element={<FProfile />}
        />
        <Route path="/Chat" element={<Chat />} />
        <Route path="/Msg" element={<Msg />} />
        <Route path="/TNC" element={<TNC />} />
        <Route path="/Hook" element={<Hook />} />
        <Route path="/Contact" element={<Contact />} />
        {/* <Route path="/" element={<Hero />} /> */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/pro" element={<ProductPro />} />
        <Route path="/explore" element={<Explore />} />
        <Route
          path="/product-details/:productId"
          element={<ProductDetails />}
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/collaborations" element={<Collaborations />} />
        <Route path="/she" element={<Explore />} />
        <Route path="/order" element={<Order />} />
        <Route path="/delivery" element={<DeliveryPolicy />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        {/* <Route path="/Customization" element={<CusCall />} /> */}
        <Route path="/Buy" element={<New />} />
      </Routes>
    </div>
  );
};

export default Home;
